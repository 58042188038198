//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { chunk } from "@/common/lib/arrays";
export default {
  name: "MnemonicList",
  props: {
    mnemonicCollection: Array
  },
  data: () => ({
    chuckLength: 4
  }),
  computed: {
    chunkedMnemonic() {
      return chunk(this.mnemonicCollection, this.chuckLength);
    }
  }
};